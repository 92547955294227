import {Helmet} from "react-helmet";

interface MetaData {
    title: string;
    description: string;
}

export const Meta = ({title, description} : MetaData) => {
    return (
        <>
            <Helmet>
                <title>{title} | A. Page Code</title>
                <meta name="description" content={description} />
            </Helmet>
        </>
    )
}