import { NavLink } from 'react-router-dom';

import '../styles/layout/_header.scss';
import {ReactComponent as Logo} from '../images/logo.svg';

export default function Header() {
    return <header>
        <NavLink className="logo-wrapper" exact to="/"><Logo className="logo" /></NavLink>
        <ul>
            <li><NavLink to="/services">Services</NavLink></li>
            <li><NavLink to="/projects">Projects</NavLink></li>
            <li><NavLink to="/clients">Clients</NavLink></li>
            <li><NavLink to="/contact">Contact</NavLink></li>
        </ul>
    </header>;
}