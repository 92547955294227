// Global Imports
import ReactDOM from "react-dom";
import React, { Suspense, lazy, Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Global Styles
import './styles/main.scss';

// Partials
import Header from "./partials/header";
import Footer from "./partials/footer";
import Loader from "./partials/loader";

// Routes
import { ProjectList, ProjectDetail } from './pages/projects';

// Lazy Routes
const Home = lazy(() => import('./pages/home'));
const Clients = lazy(() => import('./pages/clients'));
const Services = lazy(() => import('./pages/services'));
const Contact = lazy(() => import('./pages/contact'));
const Error = lazy(() => import('./pages/404'));


/*import { ROUTES, ROUTE } from "./helpers/navigation";
const RouteSetup = {ROUTES.map((route: ROUTE, i) => {
    return <Route path={route.path} component={route.component} />;
})}*/


// Create App
class App extends Component {
    state = {
        loading: true,
        clients: [],
        projects: []
    };

    componentDidMount() {
        if(!this.state.clients.length) {
            fetch('https://2021.apagecode.com/feed.json')
                .then(res => res.json())
                .then((data) => {
                    this.setState({ loading: false, clients: data.clients, projects: data.projects })
                })
                .catch(console.log)
        }
    }

    render() {
        return (
            <Router>
                <Header/>
                <main>
                    <Suspense fallback={Loader}>
                        <Switch>
                            <Route exact path="/">
                                <Home/>
                            </Route>
                            <Route exact path="/clients">
                                <Clients clients={this.state.clients}/>
                            </Route>
                            <Route exact path="/projects">
                                <ProjectList projects={this.state.projects}/>
                            </Route>
                            <Route path="/projects/:projectID">
                                <ProjectDetail projects={this.state.projects} loading={this.state.loading}/>
                            </Route>
                            <Route path="/services" component={Services}/>
                            <Route path="/contact" component={Contact}/>
                            <Route path="/*" component={Error}/>
                        </Switch>
                    </Suspense>
                </main>
                <Footer/>
            </Router>
        )
    }
};

// Render App
ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.querySelector('.container')
);