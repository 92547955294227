import { Link, useParams } from "react-router-dom";
import { Meta } from "../partials/meta";
import Loader from "../partials/loader";
import Error from "./404";
import decodeHtml from "../helpers/decode";

// Project Styles
import '../styles/components/projects.scss';


interface ProjectType {
    site_id: string;
    site_client: string;
    site_title: string;
    site_url: string;
    site_design: string;
    site_design_link: string;
    site_features: string;
    site_languages: string;
    site_info: string;
    site_link: string;
    site_image: string;
    site_video: string;
}

interface ProjectTypes {
    loading?: boolean;
    projects: ProjectType[];
}

export const ProjectList = ({projects} : ProjectTypes) => {
    return (
        <>
            <h1>Projects</h1>
            <div className="project-list">
                <Meta title="Projects" description="A List of Projects"/>
                {projects.map((project: ProjectType) => (
                    <div className="project-card" key={project.site_id}>
                        <Link to={`/projects/${project.site_url}`}><h5>{project.site_title || project.site_client}</h5></Link>
                    </div>
                ))}
            </div>
        </>
    )
};

export const ProjectDetail = ({projects, loading} : ProjectTypes) => {
    const { projectID } = useParams<{ projectID: string }>();
    const project = projects.filter(obj => {
        return obj.site_url === projectID;
    })[0];

    if(project) {
        return (
            <div className="project">
                <Meta title={project.site_title || project.site_client} description="A List of Projects"/>
                <h1>{decodeHtml(project.site_client)}</h1>
            </div>
        )
    } else if(loading) {
        return <Loader/>
    } else {
        return <Error/>
    }

}